import { navigate } from 'gatsby';
import React from 'react';

import { Button } from '@components/controls/button';
import { Card } from '@components/layout/card';
import { Center } from '@components/layout/center';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useDetectMedia } from '@hooks/useDetectMedia';

const Complete = () => {
  const { md } = useDetectMedia();
  return (
    <Card
      bg="gray-second"
      width={md ? '719px' : '90%'}
      mx="auto"
      px={md ? 'auto' : '20px'}
      py={md ? '50px' : '20px'}
    >
      <VStack spacing="27px">
        <Center>
          <Paragraph fontSize="30px" color="orange" align="center" fontWeight="bold">
            会員登録完了
          </Paragraph>
        </Center>
        <Paragraph fontWeight="regular" fontSize="18px" lh="18px" align="center">
          会員登録が完了しました。
        </Paragraph>
        <Paragraph fontWeight="regular" fontSize="18px" lh="18px" align="center">
          以下のボタンをクリックして、Tasty モールのご利用をお楽しみください。
        </Paragraph>
        <Button
          radius="10px"
          as="a"
          onClick={() => navigate('/')}
          width="230px"
          height="60px"
          bg="orange"
          color="white"
        >
          <Paragraph fontWeight="700" fontSize="16px" lh="normal" color="white" align="center">
            TOP
          </Paragraph>
        </Button>
      </VStack>
    </Card>
  );
};

export default Complete;
